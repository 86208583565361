@import '../../variables.scss';

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 60px 0px;

    .result-container {
        width: $content-size-desk;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: $mobile) {
            width: 95%;
        }
    }
}