@import '../../../variables.scss';

.inputNajjar {
    display: flex;
    flex-direction: column;
    max-width: 200px;

    label {
        font-size: 12px;
        display: block;
        user-select: none;
    }

    input {
        display: block;
        border: 0px;
        padding: 4px;
        
        background-color: transparent;
    
        border-bottom: 2px solid black;
        transition: border 0.5s;
        
        &:focus-visible {
            outline: 0px;
            border-bottom: 2px solid $primary;
        }
    
        &:disabled {
            border-bottom: 2px solid $gray !important;
        }
    }
}